import React from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";

import Hero from "../views/ContactUs/Hero";
import Information from "../views/ContactUs/Information";
import Contact from "../views/ContactUs/Contact";

function ContactUs() {
  return (
    <Box>
      <Hero />
      <AnimatedContent>
        <Information />
        <Contact />
      </AnimatedContent>
    </Box>
  );
}

export default ContactUs;
