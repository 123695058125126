import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContactInfo from "./ContactInfo";
import MapView from "./MapView";
import animation from "./_animation";

function Information() {
  return (
    <Box paddingY="3.5xl" minHeight="viewContactH" backgroundColor="light.400">
      <PageContainer>
        <Box maxWidth={{ base: "100%", sm: "70%", lg: "100%" }} margin="0 auto">
          <Flex
            justifyContent={{ base: "", lg: "space-between" }}
            flexDirection={{ base: "column", lg: "row" }}
          >
            <ViewportAnimation {...animation.info}>
              <ContactInfo />
            </ViewportAnimation>
            <ViewportAnimation {...animation.map1}>
              <ViewportAnimation {...animation.map2}>
                <MapView />
              </ViewportAnimation>
            </ViewportAnimation>
          </Flex>
        </Box>
      </PageContainer>
    </Box>
  );
}

export default Information;
