import React from "react";
import { Box, Img } from "@chakra-ui/react";
import imgMap from "../../../images/contact-map.png";

function MapView() {
  return (
    <Box
      maxHeight={{ base: "", lg: "viewContactMapH" }}
      maxWidth={{ base: "100%", lg: "viewContactMapW" }}
    >
      <Img draggable={false} src={imgMap} maxWidth="100%" maxHeight="100%" />
    </Box>
  );
}

export default MapView;
