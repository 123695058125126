import React from "react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import ContentTitle from "../../../components/ContentTitle";
import ContactBlock from "../../common/Contact";

function Contact() {
  const { language, t } = useTranslation();

  return (
    <ContactBlock>
      <ContentTitle
        titleBold={t("page@contact@title-bold")}
        titleLight={t("page@contact@title-light")}
        smallFont={"hy-AM" === language}
        // order={"en-US" !== language ? "break" : "inline"}
        order={"hy-AM" !== language ? "break" : "inline"}
        keepOrder={"hy-AM" === language}
      >
        {/* {t("page@contact@text")} */}
      </ContentTitle>
    </ContactBlock>
  );
}

export default Contact;
