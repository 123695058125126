import React from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import InfoRow from "./InfoRow";

function ContactInfo() {
  const { t } = useTranslation();

  return (
    <Box maxWidth="viewContactInfoW">
      <InfoRow
        type="phone"
        title={t("page@contact@title@phone")}
        lines={["+374 12 20 27 44"]}
      />
      <InfoRow
        type="email"
        title={t("page@contact@title@email")}
        lines={["Info@berkeleyfund.com"]}
      />
      <InfoRow
        type="address"
        title={t("page@contact@title@address")}
        lines={[
          t("page@contact@title@address-value-1"),
          t("page@contact@title@address-value-2"),
        ]}
      />
    </Box>
  );
}

export default ContactInfo;
