import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

function InfoRow({ type, title, lines }) {
  return (
    <Box marginBottom="2.2xl">
      <Heading
        fontWeight="bold"
        fontSize={{ base: "md", md: "xl" }}
        marginBottom="5"
        lineHeight={{ base: "0", md: "7" }}
      >
        {title}
      </Heading>

      {lines.map((line, i) => (
        <Text
          key={`contact-row-info-${type}-${i}`}
          fontWeight="light"
          fontSize={{ base: "2sm", md: "2xl" }}
          lineHeight="10"
        >
          {line}
        </Text>
      ))}
    </Box>
  );
}

export default InfoRow;
