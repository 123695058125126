const infoAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-1.5rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.75,
  },
};

const mapAnimation1 = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0.75,
    transform: "scale(1)",
  },
  animate: {
    opacity: 1,
    transform: "scale(0.75)",
  },
  transition: {
    duration: 0.85,
    delay: 0.75,
  },
};

const mapAnimation2 = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    transform: "scale(0)",
  },
  animate: {
    opacity: 1,
    transform: "scale(1.2)",
  },
  transition: {
    duration: 0.55,
    delay: 0.85,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  info: infoAnimation,
  map1: mapAnimation1,
  map2: mapAnimation2,
};
